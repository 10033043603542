import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Image from 'gatsby-image'

// Libraries
import _ from 'lodash'

// Components
import { BannerAlt } from 'components/Elements'
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import RequestOffer from 'components/RequestOffer'

// Styles
import 'components/Services/Services.scss'
import 'styles/templates/ServiceTemplate.scss'

// Icons
import tick from 'img/icon/tick.svg'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      path
      acf {
        title
        description
        videoURL: video_url
        image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 1024, toFormat: JPG) {
                src
              }
            }
          }
        }
        extraImage: extra_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 1024, toFormat: JPG) {
                src
              }
            }
          }
        }
        quote
        points {
          title
        }
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: {
      path,
      acf: { title, description, image, extraImage, quote, points, videoURL },
      yoastMeta,
    },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} image={image.localFile.childImageSharp.fixed.src} article />
      <div className="page-template service-template">
        <BannerAlt>
          <BackgroundImage
            className="banner-background-image"
            fluid={image.localFile.childImageSharp.fluid}
          />
          <div className="banner-content row pt-5">
            <div className="col-lg-6 pt-5">
              <h1 className="mt-5">
                <div dangerouslySetInnerHTML={{ __html: title }} />
              </h1>
            </div>
            <div className="col-lg-6" />
          </div>
        </BannerAlt>

        <div className="services container">
          <div className="row">
            <div className="col-lg-8 pr-lg-5">
              <div
                className="page-container container font-family-secondary text-justify mt-5 font-size-sm font-family-secondary"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
            <div className="service-template-right col-lg-4 pt-5">
              <div className="service-even px-lg-4">
                <div className="">
                  {_.map(points, ({ title }, index) => (
                    <div
                      className="service-point mt-3 font-size-sm font-family-secondary"
                      key={index}
                    >
                      <div className="row">
                        <div className="col-2 text-right">
                          <img className="service-tick d-inline-block" src={tick} alt="" />
                        </div>
                        <div className="col-10">{title}</div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="d-flex mt-5 mb-3 justify-content-center justify-content-lg-end align-items-lg-center">
                  <div
                    className="service-quote position-relative"
                    dangerouslySetInnerHTML={{ __html: quote }}
                  />
                </div>
                <Image fluid={extraImage.localFile.childImageSharp.fluid} />
              </div>
            </div>
          </div>
        </div>

        {videoURL && (
          <div className="container mt-5 pt-3 text-center">
            <h3 className="service-template-video-title mt-5">
              <div>
                <p>BEKIJK HIER ONZE</p>
                <p>
                  <strong>PROMOTIEFILM</strong>
                </p>
              </div>
            </h3>

            <div className="mt-md-5">
              <iframe
                src={videoURL}
                title={title}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                width="640"
                height="360"
                autoPlay={false}
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              />
            </div>
          </div>
        )}            

        <div className="mt-5 pt-5">
          <RequestOffer isRed />
        </div>
      </div>
    </Layout>
  )
}

export default PageTemplate
